import React from "react";
import { Layout } from "../../components/Layout";
import { Head } from "../../components/Head";
import { CaseStudiesTitle } from "../../components/CaseStudiesTitle";
import { CaseStudiesText } from "../../components/CaseStudiesText";

export default function CS_Neurosurgery() {
  const title = "Neurosurgery";
  const subTitle = "脳神経外科";
  const overview = [
    "他の診療科と比べると極めて少ない診療科です",
    <br />,
    "脳神経外科の開業資金は、画像診断装置を持つか持たないかで大きく異なり、",
    <br />,
    "6,000万円から2億5,000万円とかなり幅があります",
  ];
  const openingCash = "おおよその開業資金";
  const openingCashText = [
    "土地、建物　7,000万円～15,000万円",
    <br />,
    "運用資金　3,000万円〜",
    <br />,
    "設備　8,000万円〜",
    <br />,
    "電子カルテ、レジスター、コピー複合機、診察用ベッド、X線撮影装置",
    <br />,
    "MRI装置、超音波診断装置、脳波測定器、知能検査装置",
  ];
  const point = "ポイント";
  const pointText = [
    "・スモールスタートで開業",
    <br />,
    "・病院連携を図る",
    <br />,
    "・ランニングコストを正確に見積もる",
    <br />,
    <br />,
    "画像センター等を活用することで高価なMRI装置を設置しなくても開業が可能のため、",
    <br />,
    "地域病院や画像センターとの連携を図ることが重要",
    <br />,
    "機器を持つ場合は開発初期投資額が他の診療科目と比較すると二倍以上の規模となるため",
    <br />,
    "開業前から集患対策をすることが重要になります",
    <br />,
    "また、機器のメンテナンスやランニングコストはかなり高価となるため、正確に見積もりが必要",
  ];

  return (
    <Layout>
      <Head props={subTitle} />
      <CaseStudiesTitle title={[title, subTitle]} />
      <CaseStudiesText
        textData={[overview, openingCash, openingCashText, point, pointText]}
      />
    </Layout>
  );
}
